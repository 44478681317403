import React,{useState} from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import img from "../assets/images/Aerated-Block-Industry.png";
import arrowImage from "../assets/images/downArrow.png";
import { IoArrowForward } from "react-icons/io5";
import Pharmaceuticals from "../assets/images/pharmaceuticals.jpg";
import "./IndustrySec.css";
import Tyre from "../assets/images/tyre.jpg";
import Paper from "../assets/images/paper.jpg";
import Textile from "../assets/images/textile.jpg";
import FoodBeverages from "../assets/images/food-beverages.jpg";
import ChemicalPetrochemical from "../assets/images/chemical-petrochemical.jpg";
import OilRefinery from "../assets/images/oil-refinery.jpg";
import ButtonArrow from "../assets/images/button-arrow.svg";


const IndustrySec = (props) => {
  console.log("props", props.industry);
    let data = {};
  if (props.industry === "food-beverages") {
    data.image = FoodBeverages;
    data.description =
      "Maintain hygiene and efficiency in food and beverage production with our reliable sealing and steam management solutions, ensuring safe and leak-free operations";
    data.title = "Food & Beverage";
  }
  if (props.industry === "paper") {
    data.image = Paper;
    data.description =
      "Improve paper production processes with our durable valves and steam traps, designed to handle high temperatures and pressures efficiently";
    data.title = "Paper";
  }
  if (props.industry === "textiles") {
    data.image = Textile;
    data.description =
      "Optimize textile manufacturing with our efficient steam and condensate management solutions, ensuring smooth and energy-efficient operations";
    data.title = "Textile";
  }
  if (props.industry === "tyre-manufacturing") {
    data.image = Tyre;
    data.description =
      "Enhance tyre production with our leak-proof valves and effective steam traps, designed to withstand the high temperatures and pressures of the manufacturing process";
    data.title = "Tyre Manufacturing";
  }
  if (props.industry === "oil-refinery") {
    data.image = OilRefinery;
    data.description =
      "Enhance efficiency and safety with our leak-proof valves, gaskets, and steam traps, designed to handle the demanding conditions of oil refining processes";
    data.title = "Oil Refineries";
      }
  if (props.industry === "chemical-petrochemical") {
    data.image = ChemicalPetrochemical;
    data.description =
      "Ensure zero leakage and optimal process control with our robust valves, gaskets, and condensate management solutions, tailored for chemical and petrochemical applications";
    data.title = "Chemical & Petrochemical Plants";
      }
  if (props.industry === "pharmaceutical") {
    data.image = Pharmaceuticals;
    data.description =
      "Meet stringent pharmaceutical standards with our high-performance valves, gaskets, and steam traps, ideal for applications involving pure steam and critical media";
    data.title = "Pharmaceuticals";
    }
  return (
    <div className="flex w-[100%] mb-[100px]">
      <div
        className=" flex flex-col justify-start"
        style={{ height: "600px", maxHeight: "600px" }}
      >
        <h1 className="flex justify-start text-6xl poppins">
          <span className="font-semibold poppins mr-[8px]">Solutions</span>{" "}
          Industry Wise
        </h1>
        <div>
          <div>
            <div className="border relative  border-[#CFCCC4] p-4">
              <img src={data.image} style={{width:'100%'}} />
              <div className="absolute bottom-[30px] left-[40px]">
                {/* <div className="flex items-center justify-center w-[16rem] bg-black bg-opacity-45 border-1.5 border-gray-400 rounded-full">
                  <div className="font-normal p-4 pl-[3rem] flex w-[14rem] flex justify-center items-center color-white">
                    Most Reliable
                </div>
</div> */}
                <div className="carousel-solution-title">{data.title}</div>
              </div>
            </div>
  
            <div>
              <div className="carousel-solution-description">
                {data.description}
              </div>
            </div>
            <div>
              <button
                className="card-button-class d-flex items-baseline " style={{ marginTop:'20px',padding:"18px 40px 18px 40px" }}
              >
                <span className="discoverNow mr-5">Discover Now</span>
                <span>
                  <img
                    src={ButtonArrow}
                    alt="arrow"
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-[50%]">
        <div></div>
        <div></div>
      </div> */}
    </div>
  );
  };

export default IndustrySec;
