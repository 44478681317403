import React, { useState, useEffect } from "react";
import "../components/ProductDetail.css";
import WhiteLocation from "../assets/images/white-location.svg";
import SmallMap from "../assets/images/small-map.png";
import WhitePhone from "../assets/images/white-phone.svg";
import WhiteMail from "../assets/images/white-mail.svg";
import TextField from "@mui/material/TextField";
import ButtonArrow from "../assets/images/button-arrow.svg";
import productDetail1 from "../assets/images/product1.png";

import { IoArrowForward } from "react-icons/io5";
import MobileProductBanner from "../assets/images/productMainMobileBanner.png";
import ProductsBanner from "../assets/images/insighrtsDetailsBanner.png";
import BlogImage from "../assets/images/Blog.png";
import Watermark from "../assets/images/new-watermark.png";
import Share from "../assets/images/Share.png";
import Accordion from "./Accordian";
import ProductDetails5 from "../assets/images/product-details5.png";
import TataSteel from "../assets/images/tata-steel.png";
import CaseStudies from "./CaseStudies";
import { Helmet } from "react-helmet-async";
import productDetail from "../assets/images/product-details.png";

const BlogDetail = () => {
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const data = [
    {
      id: 1,
      questions:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?",
      answer:
        "Impressions are a big deal when it comes to oSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    },
    {
      id: 2,
      questions:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?",
      answer:
        "Impressions are a big deal when it comes to oSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    },
    {
      id: 3,
      questions:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?",
      answer:
        "Impressions are a big deal when it comes to oSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    },
  ];
  const [productTab, setProductTab] = useState("products");
  const handleProduct = (tab) => {
    console.log(tab);
    setProductTab(tab);
  };
  const [toggledStates, setToggledStates] = useState({
    product: false,
    size: false,
    specification: false,
    keyfeatures: false,
  });

  const handleToggle = (section) => {
    setToggledStates((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  useEffect(() => {
    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setScreenWidth(entry.contentRect.width);
      }
    });

    // Start observing the selected element
    resizeObserver.observe(document.body);
  }, [screenWidth]);
  const isMobile = screenWidth <= 820;

  return (
    <>
      <Helmet>
        <title>Blog Details</title>
      </Helmet>
      <div
        className={screenWidth > 820 ? " pt-120 fluid-container" : "pl-[5%] pr-[5%] fluid-container "}
        itemScope
        itemType="https://schema.org/Product"
      >
        <div
          className=" product-details-container flex item-center h-450"
          itemProp="mainEntity"
          itemScope
          itemType="https://schema.org/Product"
        >
          <div className="col-md-8 grid gap-10">
            <div className="product-image" style={{ height: 332 }}>
              <img
                src={isMobile ? MobileProductBanner: ProductsBanner}
                alt="product-Detail"
                style={{  height: "100%",width:'100%' }}
                itemProp="image"
              />
            </div>

            <div className=" flex flex-row">
              <div
                style={{ backgroundColor: "#D4DDE7", color: "#221F20AB" }}
                className="blogTags "
                itemProp="name"
              >
                Lifestyle
              </div>
              <div
                style={{
                  backgroundColor: "#221F200F",
                  color: "#221F20AB",
                }}
                className="blogTags ml-2"
                itemProp="name"
              >
                5 min Read
              </div>
            </div>
            <div className="product-card-subtitle fullWidth text-[16px]" itemProp="description">
              Uni Klinger manufactures a wide range of fluid control products
              like Piston Valves, Bellow Seal Valves, Steam Traps, etc. They
              focus on providing unique solutions that address shortcomings in
              existing products.
            </div>
<div className="col-md-12 p-[0px] inline-flex  md:inline-flex gap-4 flex-wrap md:flex-nowrap">
<div 

              className="industry-card col-md-6 "
              itemScope
              itemType="https://schema.org/Product"
            >
              <div>
                <img
                  // src={BaseUrl.concat(
                  //   card.attributes.Image.data.attributes.url
                  // )}
                  src={productDetail}
                  style={{ height: 269, width: "100%" }}
                  className="industry-image"
                  itemProp="image"
                />
<div className="industry-content p-2" style={{backgroundColor:'#E7ECF4'}}>
                <div className=" flex flex-row  items-center justify-between ">
                  <div
                    style={{fontFamily:'DM Sans',fontWeight:700,fontSize:16}}
                  >
                    Graphite foil<br/>
                    <span>(UKL GS)</span>
                  </div>
                <div>
                <button  style={{marginTop:0}}className="view-product  text-center d-flex items-center">
                                <span className=" text-[12px] mr-1">
                                  View Product
                                </span>
                                <span>
                             
                                </span>
                              </button>
                </div>
              
                </div>

               
              </div>

              </div>
              
            </div>
            <div 

className="industry-card col-md-6 "
itemScope
itemType="https://schema.org/Product"
>
<div>
  <img
    // src={BaseUrl.concat(
    //   card.attributes.Image.data.attributes.url
    // )}
    src={productDetail}
    style={{ height: 269, width: "100%" }}
    className="industry-image"
    itemProp="image"
  />
<div className="industry-content p-2" style={{backgroundColor:'#E7ECF4'}}>
  <div className=" flex flex-row  items-center justify-between ">
    <div
      style={{fontFamily:'DM Sans',fontWeight:700,fontSize:16}}
    >
      Graphite foil<br/>
      <span>(UKL GS)</span>
    </div>
  <div>
  <button  style={{marginTop:0}}className="view-product  text-center d-flex items-center">
                  <span className=" text-[12px] mr-1">
                    View Product
                  </span>
                  <span>
               
                  </span>
                </button>
  </div>

  </div>

 
</div>

</div>

</div>
</div>
           

            <div className="product-card-subtitle fullWidth text-[16px] "  itemProp="description">
              Uni Klinger manufactures a wide range of fluid control products
              like Piston Valves, Bellow Seal Valves, Steam Traps, etc. They
              focus on providing unique solutions that address shortcomings in
              existing products. Uni Klinger manufactures a wide range of fluid
              control products like Piston Valves, Bellow Seal Valves, Steam
              Traps, etc. They focus on providing unique solutions that address
              shortcomings in existing products. Uni Klinger manufactures a wide
              range of fluid control products like Piston Valves, Bellow Seal
              Valves, Steam Traps, etc. They focus on providing unique solutions
              that address shortcomings in existing products. Uni Klinger
              manufactures a wide range of fluid control products like Piston
              Valves, Bellow Seal Valves, Steam Traps, etc. They focus on
              providing unique solutions that address shortcomings in existing
              products.
            </div>
            <div className="col-md-12 p-[0px] inline-flex  md:inline-flex gap-4 flex-wrap md:flex-nowrap">
<div 

              className="industry-card col-md-6 "
              itemScope
              itemType="https://schema.org/Product"
            >
              <div>
                <img
                  // src={BaseUrl.concat(
                  //   card.attributes.Image.data.attributes.url
                  // )}
                  src={productDetail}
                  style={{ height: 269, width: "100%" }}
                  className="industry-image"
                  itemProp="image"
                />
<div className="industry-content p-2" style={{backgroundColor:'#E7ECF4'}}>
                <div className=" flex flex-row  items-center justify-between ">
                  <div
                    style={{fontFamily:'DM Sans',fontWeight:700,fontSize:16}}
                  >
                    Graphite foil<br/>
                    <span>(UKL GS)</span>
                  </div>
                <div>
                <button style={{marginTop:0}}className="view-product text-center d-flex items-center">
                                <span className=" text-[12px] mr-1">
                                  View Product
                                </span>
                                <span>
                             
                                </span>
                              </button>
                </div>
              
                </div>

               
              </div>

              </div>
              
            </div>
            <div 

className="industry-card col-md-6 "
itemScope
itemType="https://schema.org/Product"
>
<div>
  <img
    // src={BaseUrl.concat(
    //   card.attributes.Image.data.attributes.url
    // )}
    src={productDetail}
    style={{ height: 269, width: "100%" }}
    className="industry-image"
    itemProp="image"
  />
<div className="industry-content p-2" style={{backgroundColor:'#E7ECF4'}}>
  <div className=" flex flex-row  items-center justify-between ">
    <div
      style={{fontFamily:'DM Sans',fontWeight:700,fontSize:16}}
    >
      Graphite foil<br/>
      <span>(UKL GS)</span>
    </div>
  <div>
  <button  style={{marginTop:0}} className="view-product  text-center d-flex items-center">
                  <span className=" text-[12px] mr-1">
                    View Product
                  </span>
                  <span>
               
                  </span>
                </button>
  </div>

  </div>

 
</div>

</div>

</div>
</div>

<div className="product-card-subtitle fullWidth text-[16px]"  itemProp="description">
Uni Klinger manufactures a wide range of fluid control products like Piston Valves, Bellow Seal Valves, Steam Traps, etc. They focus on providing unique solutions that address shortcomings in existing products. Uni Klinger manufactures a wide range of fluid control products like Piston Valves, Bellow Seal Valves, Steam Traps, etc. They focus on providing unique solutions that address shortcomings in existing products.
            </div>
          </div>

          <div className="col-md-4 product-details">
            <div
              className="flex justify-between items-center product-title-container"
              itemScope
              itemProp="name"
            >
              <div className="product-detail-title">
                <span className="poppins" style={{ fontSize: 20 }}>
                  Related <span className="font-bold">Blogs</span>
                </span>
              </div>
            </div>

            <div
              className="industry-card mt-10"
              itemScope
              itemType="https://schema.org/Product"
            >
              <div>
                <img
                  // src={BaseUrl.concat(
                  //   card.attributes.Image.data.attributes.url
                  // )}
                  src={BlogImage}
                  style={{ height: 172, width: "100%" }}
                  className="industry-image"
                  itemProp="image"
                />
              </div>
              <div className="industry-content bg-white p-20">
                <div className=" flex flex-row pb-10 ">
                  <div
                    style={{ backgroundColor: "#D4DDE7", color: "#221F20AB" }}
                    className="blogTags "
                    itemProp="name"
                  >
                    Lifestyle
                  </div>
                  <div
                    style={{
                      backgroundColor: "#221F200F",
                      color: "#221F20AB",
                    }}
                    className="blogTags ml-2"
                    itemProp="name"
                  >
                    5 min Read
                  </div>
                </div>

                <div className="blog-description pb-10" itemProp="description">
                  {/* {card.attributes.Description} */}
                  Providing unique solutions that address shortcomings in
                  existing products.
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div className="poppins" style={{ fontSize: 14 }}>
                    19 Feb, 2023
                  </div>
                  <div>
                    <div className="md:mt-[0px] mt-10 d-flex items-center">
                      <a
                        href="/about-us"
                        title="About Us"
                        className="font-semibold text-black text-[14px] md:text-[16px] m-2 md:pt-3"
                      >
                        Discover More
                      </a>
                      <IoArrowForward className="text-black md:m-2 origin-bottom -rotate-45 size-5 md:size-8" />
                    </div>
                    <div
                      className="gradLine"
                      style={{
                        marginLeft: 7,
                        width: "120px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="industry-card mt-10"
              itemScope
              itemType="https://schema.org/Product"
            >
              <div>
                <img
                  // src={BaseUrl.concat(
                  //   card.attributes.Image.data.attributes.url
                  // )}
                  src={BlogImage}
                  style={{ height: 172, width: "100%" }}
                  className="industry-image"
                  itemProp="image"
                />
              </div>
              <div className="industry-content bg-white p-20">
                <div className=" flex flex-row pb-10 ">
                  <div
                    style={{ backgroundColor: "#D4DDE7", color: "#221F20AB" }}
                    className="blogTags "
                    itemProp="name"
                  >
                    Lifestyle
                  </div>
                  <div
                    style={{
                      backgroundColor: "#221F200F",
                      color: "#221F20AB",
                    }}
                    className="blogTags ml-2"
                    itemProp="name"
                  >
                    5 min Read
                  </div>
                </div>

                <div className="blog-description pb-10" itemProp="description">
                  {/* {card.attributes.Description} */}
                  Providing unique solutions that address shortcomings in
                  existing products.
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div className="poppins" style={{ fontSize: 14 }}>
                    19 Feb, 2023
                  </div>
                  <div>
                    <div className="md:mt-[0px] mt-10 d-flex items-center">
                      <a
                        href="/about-us"
                        title="About Us"
                        className="font-semibold text-black text-[14px] md:text-[16px] m-2 md:pt-3"
                      >
                        Discover More
                      </a>
                      <IoArrowForward className="text-black md:m-2 origin-bottom -rotate-45 size-5 md:size-8" />
                    </div>
                    <div
                      className="gradLine"
                      style={{
                        marginLeft: 7,
                        width: "120px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default BlogDetail;
