import React, { useState, useEffect } from "react";
import HomePageSlider from "./Carousel";
import TopCarousel from "./TopCarousel";
import PartnerUs from "./PartnerUs";
import About from "./About";
import Testimonial from "./Testimonial";
import CaseStudies from "./CaseStudies";
import ProductsOffer from "./ProductsOffer";
import IndustrySec from "./IndustrySec";
import Form from "./Form";
import { Helmet } from "react-helmet-async";
import SolutionsCarousel from './SolutionsCarousel';
import MobileIndustrySolution from "./MobileIndustrySolution";
import { MapContainer, TileLayer, Marker, Popup as LeafletPopup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import "./Home.css";
import { ReactComponent as WorldMap } from '../assets/Maskgroup.svg';
import BlueMarker from "../assets/images/blueMarker.png";
import IndustryExample from "../assets/images/industry-example.svg";
import OilRefineries from "../assets/images/oil-refineries.svg";
import ChemicalPetrochemical from "../assets/images/chemical-petrochemical.svg";
import Pharmaceutical from "../assets/images/pharmaceuticals.svg";
import FoodBaverage from "../assets/images/food-beverage.svg";
import Paper from "../assets/images/paper.svg";
import Textiles from "../assets/images/textiles.svg";
import TyreManufacturing from "../assets/images/tyre-manufacturing.svg";
import FancyCarousel from "react-fancy-circular-carousel";
import 'react-fancy-circular-carousel/FancyCarousel.css';
const customIcon = new L.Icon({
  iconUrl: BlueMarker,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const locations = [
  {
    position: [400.0, 200],
    country: "North America",
    address: "1700 North Moore St, Suite 1520, Arlington, VA 22209-1911",
    phone: "+1 (703) 243 9787",
    fax: "+1 (703) 243 9791",
    email: "northamerica@tata.com",
    googleMaps: "https://goo.gl/maps/MXSRWXkMjBjBnr6u8"
  },
  {
    position: [200.5002, 500.1421],
    country: "Europe",
    address: "18, Grosvenor Place, London SW1X 7HSc",
    phone: "+44 (20) 7235 8281",
    fax: "+44 (20) 7235 8727",
    email: "tata@tata.co.uk",
    googleMaps: "https://goo.gl/maps/oeTt22Ne4z6QZzTz8"
  },
];

const Popup = ({ isVisible, position, content }) => (
  isVisible ? (
    <div style={{ position: 'absolute', top: position.y, left: position.x }}>
      {content}
    </div>
  ) : null
);

function Home() {
  
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
const [screenWidth,setScreenWidth] = useState(0);
const [zoomLevel, setZoomLevel] = useState(1);
const [focusElement, setFocusElement] = useState(0);

const images = [
  OilRefineries,
  ChemicalPetrochemical,
  Pharmaceutical,
  FoodBaverage,
  Paper,
  Textiles,
  TyreManufacturing,
];
const info = ['Delhi', 'Mumbai', 'Bengaluru', 'Kolkata'];
  const [height, setHeight] = useState("700px"); // Default height
  useEffect(() => {
// Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setScreenWidth(entry.contentRect.width);
      }
    });

// Start observing the selected element
    resizeObserver.observe(document.body);
}, [screenWidth]);



useEffect(() => {
  const updateScreenInfo = () => {
    setScreenWidth(window.innerWidth);
    const zoom = window.outerWidth / window.innerWidth;
    setZoomLevel(zoom);
    setHeight(`${700 * zoom}px`); // Adjust height based on zoom level
  };

  updateScreenInfo();

  window.addEventListener("resize", updateScreenInfo);

  return () => {
    window.removeEventListener("resize", updateScreenInfo);
  };
}, []);



  return (
    <>
      <Helmet>
        <title>Home</title>
        <link rel="canonical" href="https://uniklinger.com" />

      </Helmet>
    
        <TopCarousel />
   
        <ProductsOffer />
        {screenWidth < 1024 && <MobileIndustrySolution />}

        <SolutionsCarousel style={{height:height}}/>

      
         <About />


        <PartnerUs />
{/* <h1 className="text-5xl mt-[136px]">
          <span className="font-bold ">Case</span> Studies
        </h1>
        <CaseStudies />
        <Testimonial /> */}
        <Form />
     
          </>
  );
}

export default Home;
