import React, { useEffect, useState } from "react";
import MainBanner from "../assets/images/industry-solution-banner.png";
import MobileBanner from "../assets/images/solution-mobile-banner.png";
import ButtonArrow from "../assets/images/button-arrow.svg";
import axios from "axios";
import Form from "./Form";
import { Helmet } from "react-helmet-async";
import { IoArrowForward } from "react-icons/io5";
import MobileProductBanner from "../assets/images/productMainMobileBanner.png";
import ProductsBanner from "../assets/images/InsightsBanner.png";
import BlogImage from "../assets/images/Blog.png";
import Watermark from "../assets/images/new-watermark.png";
import Share from "../assets/images/Share.png";

const Insights = () => {
  const [cards, setCards] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [hoverIndex, setHoverIndex] = useState(null);
  const BaseUrl = "https://ukladmin.3mindsdigital.com";

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCards();

    // Update screen width on resize
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchCards = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/api/industry-solutions?populate=*`
      );
      setCards(response.data.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleMouseEnter = (index) => setHoverIndex(index);
  const handleMouseLeave = () => setHoverIndex(null);

  const handleClickEnquiry = (title) => {
    const emailAddress = "salescso@uniklinger.com";
    const subject = `${title}'s Enquiry`;
    const body = `Hey, I wanted to know more about ${title}`;
    const mailtoUrl = `mailto:${encodeURIComponent(
      emailAddress
    )}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoUrl);
  };

  const isMobile = screenWidth <= 820;
  const shouldShowButtons = hoverIndex !== null || isMobile;

  return (
    <>
      <Helmet>
        <title>Blogs</title>
        <link
          rel="canonical"
          href="https://uniklinger.com/industry-solutions"
        />
      </Helmet>
      <div
        className="fluid-container"
        style={{ paddingTop: isMobile ? "100px" : 0 }}
      >
        <div
          className="banner"
          itemScope
          itemType="https://schema.org/CreativeWork"
        >
          <img
            src={isMobile ? MobileProductBanner : ProductsBanner}
            alt="Industry Solutions"
            description="Industry Solutions"
            className="banner-image"
            itemProp="image"
          />
        </div>
        <div
          style={{ height: isMobile ? 'auto': 'auto' }}
          className="product-card mt-30 bg-white row ml-0 mr-0"
          itemScope
          itemType="https://schema.org/Product"
        >
          <div className="col-md-5 p-0 w-[100%] md:w-[auto]">
            <img
              src={BlogImage} style={{height:'100%'}}
              className="banner-image p-5"
              itemProp="image"
            />
          </div>
          <div
            className="col-md-7 card-padding w-[69%]"
            style={{
              backgroundImage: `url(${Watermark})`,
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "100%",
            }}
          >
            <div className=" flex flex-row pb-10 justify-between">
                <div className=" flex flex-row">
                <div
                style={{ backgroundColor: "#D4DDE7", color: "#221F20AB" }}
                className="blogTags "
                itemProp="name"
              >
                Lifestyle
              </div>
              <div
                style={{
                  backgroundColor: "#221F200F",
                  color: "#221F20AB",
                }}
                className="blogTags ml-2"
                itemProp="name"
              >
                5 min Read
              </div>
                </div>
             
              <div>
                <img style={{ height: 31, width: 31 }} src={Share} />
              </div>
            </div>
            <div className="product-card-title " itemProp="name">
              Piston Valves & Bellow Seal Valves
            </div>
            <div className="poppins pt-20" style={{fontSize:14}}>19 Feb, 2023</div>

            <div className="product-card-subtitle pt-20" itemProp="description">
              Uni Klinger manufactures a wide range of fluid control products
              like Piston Valves, Bellow Seal Valves, Steam Traps, etc. They
              focus on providing unique solutions that address shortcomings in
              existing products.
            </div>
            
            <div className="buttons-container  d-flex" style={{paddingTop:isMobile?'50px':'100px'}}>
              <button
                className="button-class d-flex"
                itemProp="url"
                //   onClick={() => handleRedirection(card.attributes.redirect_url)}
              >
                <span className="button-arrow-padding">Read the story </span>
                <span>
                  <img
                    src={ButtonArrow}
                    alt="arrow"
                    description="arrow"
                    className="mt-8"
                  />
                </span>
              </button>
             
            </div>
          </div>
        </div>
        <div className="industry-card-containers">
          {cards.length === 0 ? (
            <div>No data available</div>
          ) : (
            cards.map((card, index) => (
              <div
                key={card.id}
                className="industry-card"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                itemScope
                itemType="https://schema.org/Product"
              >
                <div>
                  <img
                    // src={BaseUrl.concat(
                    //   card.attributes.Image.data.attributes.url
                    // )}
                    src={BlogImage}
                    style={{ height: 172, width: "100%" }}
                    alt={card.attributes.Title}
                    description={card.attributes.Description}
                    className="industry-image"
                    itemProp="image"
                  />
                </div>
                <div className="industry-content bg-white p-20">
                  <div className=" flex flex-row pb-10 ">
                    <div
                      style={{ backgroundColor: "#D4DDE7", color: "#221F20AB" }}
                      className="blogTags "
                      itemProp="name"
                    >
                      Lifestyle
                    </div>
                    <div
                      style={{
                        backgroundColor: "#221F200F",
                        color: "#221F20AB",
                      }}
                      className="blogTags ml-2"
                      itemProp="name"
                    >
                      5 min Read
                    </div>
                  </div>

                  <div
                    className="blog-description pb-10"
                    itemProp="description"
                  >
                    {/* {card.attributes.Description} */}
                    Providing unique solutions that address shortcomings in
                    existing products.
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <div className="poppins" style={{fontSize:14}}>19 Feb, 2023</div>
                    <div>
                      <div className="md:mt-[0px] mt-10 d-flex items-center">
                        <a
                          href="/insights-details"
                          title="Blog Details"
                          className="font-semibold text-black text-[14px] md:text-[16px] m-2 md:pt-3"
                        >
                          Discover More
                        </a>
                        <IoArrowForward className="text-black md:m-2 origin-bottom -rotate-45 size-5 md:size-8" />
                      </div>
                      <div
                        className="gradLine"
                        style={{
                          marginLeft: 7,
                          width: "120px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

      </div>
    </>
  );
};

export default Insights;
