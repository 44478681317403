import React, { useEffect, useState } from 'react';
import ProductsBanner from '../assets/images/ProductBanner.png';
import ButtonArrow from '../assets/images/button-arrow.svg';
import Watermark from '../assets/images/new-watermark.png';
import MobileProductBanner from '../assets/images/productMainMobileBanner.png';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import './Product.css';
import useResizeObserver from '../hooks/useResizeObserver'; // Import the custom hook
import { Breadcrumbs, Link, Typography } from '@mui/material';
import BreadcrumbsComponent from './BreadsComponent';

const CompanyUpdates = () => {
  const [cards, setCards] = useState([]);
  const screenWidth = useResizeObserver(); // Use the custom hook


  return (
    <>
      <Helmet>
        <title>Company Updates</title>
        <link rel="canonical" href="https://uniklinger.com/about-us/company-updates" />

      </Helmet>

      <div className="fluid-container" style={{ paddingTop: '60px' }}>
      <div className="banner-class" itemScope itemType="https://schema.org/Brand">
          <img
            src={screenWidth <= 800 ? MobileProductBanner : ProductsBanner}
            alt="products-banner"
            className="banner-image"
            itemProp="logo"
          />
        </div>
        <div className="product-cards-container " style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '50px'
        }}>
            <div style={{marginTop:50}}>
            <BreadcrumbsComponent/>
            </div>
       

         <div>
            <Typography style={{fontSize:40,fontWeigyht:800}}>Corporate Announcement 2024</Typography>
         </div>

         <div>
            <Typography style={{fontSize:16,color:'#DB1F51'}}>View Form_MGT_7 Annual Return FY 2020-21 {">>"}</Typography>
         </div>

         <div className='company-updates d-flex flex-row md:flex-row  items-center md:items-center  justify-between' >
<div className="col-md-6">
<Typography sx={{fontSize:{xs:16,sm:16,md:22},color:'white'}}>Let us help for a proposal and analysis of your needs</Typography>
</div>
<div className="col-md-6 d-flex justify-end">
<button  className="buttonTransparent-class">
    Contact Us
</button>
</div>
         </div>
        </div>
      </div>
    </>
  );
};

export default CompanyUpdates;
