import React, { useEffect, useState } from "react";
import AboutBanner from "../assets/images/AboutBanner2.png";
import AboutBannerMobile from "../assets/images/AbouMobiletBanner2.png";
import Backdrop from '@mui/material/Backdrop';

import "../components/AboutUs.css";
import AboutImg1 from "../assets/images/AboutImg1.png";
import AboutImg2 from "../assets/images/AboutImg2.png";
import AboutImg3 from "../assets/images/AboutImg3.png";
import AboutImg4 from "../assets/images/AboutImg4.png";

import DottoedArrow from "../assets/images/DottoedArrow.svg";
import Logo from "../assets/images/new-watermark.png";
import CircularProgress from '@mui/material/CircularProgress';

import Piston from "../assets/images/Shape.png";
import Sealed from "../assets/images/Shape2.png";
import Steam from "../assets/images/Shape3.png";
import backLeft from "../assets/images/backIconLeft.svg";
import backRight from "../assets/images/backIconRight.svg";
import certifications1 from "../assets/images/certifications1.jpeg";
import certifications2 from "../assets/images/certifications2.jpeg";
import certifications3 from "../assets/images/certifications3.svg";
import certificate1 from "../assets/certifications/pdf1.pdf";
import certificate2 from "../assets/certifications/pdf2.pdf";

import certificate3 from "../assets/certifications/pdf3.pdf";

import { Helmet } from "react-helmet-async";
import { Diversity2Sharp } from "@mui/icons-material";
import PDFThumbnail from "../utils/getpdf";

const AboutUs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [toggle, setToggle] = useState(false);
  const [partnerTab, setPartnerTab] = useState("company");
  const [locationTab, setLocationTab] = useState("manufacturing");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedPDF, setSelectedPDF] = useState(null);
const [downloading,setDownloading]=useState(false)
  const handlePDFClick = (pdfUrl) => {
    setSelectedPDF(pdfUrl);
  };

  const handleDownload = () => {
    setDownloading(true)
    const link = document.createElement('a');
    link.href = selectedPDF;
    link.download = 'document.pdf';
    link.click();
    setSelectedPDF(null);
    setDownloading(false)
  };
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const toggles = document.getElementsByClassName("toggle");
    const contentDiv = document.getElementsByClassName("content");
    const icons = document.getElementsByClassName("icon");

    for (let i = 0; i < toggles.length; i++) {
      toggles[i].addEventListener("click", () => {
        if (
          parseInt(contentDiv[i].style.height) !== contentDiv[i].scrollHeight
        ) {
          contentDiv[i].style.height = contentDiv[i].scrollHeight + "px";
          toggles[i].style.color = "var(--primary-color)";
          icons[i].classList.remove("fa-plus");
          icons[i].classList.add("fa-minus");
        } else {
          contentDiv[i].style.height = "0px";
          toggles[i].style.color = "#111130";
          icons[i].classList.remove("fa-minus");
          icons[i].classList.add("fa-plus");
        }

        for (let j = 0; j < contentDiv.length; j++) {
          if (j !== i) {
            contentDiv[j].style.height = 0;
            toggles[j].style.color = "#111130";
            icons[j].classList.remove("fa-minus");
            icons[j].classList.add("fa-plus");
          }
        }
      });
    } // Call the function once when the component mounts
  }, [toggle]);

  const handlePartner = (tab) => {
    console.log(tab);
    setPartnerTab(tab);
  };
  const handleLocation = (tab) => {
    console.log(tab);
    setLocationTab(tab);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const card = () => {
      const wiperTrack = document.querySelector(".wiper-track");
      if (!wiperTrack) return;

      const wipes = Array.from(wiperTrack.children);
      const wipePrevBtn = document.querySelector(".wiper-button__right");
      const wipeNextBtn = document.querySelector(".wiper-button__left");
      if (!wipes.length || !wipePrevBtn || !wipeNextBtn) return;

      const wipeWidth = wipes[0].getBoundingClientRect().width;
      const wrapperWidth = 1000;
      const cardSpacing = 24;

      let currentIndex = 0;

      const arrowsBehaviour = () => {
        if (currentIndex === 0) {
          wipePrevBtn.classList.add("is-hidden");
          wipeNextBtn.classList.remove("is-hidden");
        } else if (currentIndex === wipes.length - 1) {
          wipePrevBtn.classList.remove("is-hidden");
          wipeNextBtn.classList.add("is-hidden");
        } else {
          wipePrevBtn.classList.remove("is-hidden");
          wipeNextBtn.classList.remove("is-hidden");
        }
      };

      const wipeSlide = (activeSlide, nextSlide) => {
        const totalWidth = (wipeWidth + cardSpacing) * currentIndex;
        wiperTrack.style.transform = `translateX(-${totalWidth}px)`;
        activeSlide.classList.remove("active-swipe");
        activeSlide.style.transform = "scale(1)";
        nextSlide.classList.add("active-swipe");
        nextSlide.style.transform = "scale(1.1)";
      };

      const updateSlide = (newIndex) => {
        const activeSlide = wiperTrack.querySelector(".active-swipe");
        const nextSlide = wipes[newIndex];
        currentIndex = newIndex;
        wipeSlide(activeSlide, nextSlide);
        arrowsBehaviour();
      };

      wipeNextBtn.addEventListener("click", () => {
        if (currentIndex < wipes.length - 1) {
          updateSlide(currentIndex + 1);
        }
      });

      wipePrevBtn.addEventListener("click", () => {
        if (currentIndex > 0) {
          updateSlide(currentIndex - 1);
        }
      });

      wipes[0].classList.add("active-swipe");
      wipes[0].style.transform = "scale(1.1)";
      arrowsBehaviour();
    };

    card();
  }, []);
  const handleClose = () => {
    setSelectedPDF('');
  };
  return (
    <>
      <Helmet>
        <title>About us</title>
        <link rel="canonical" href="https://uniklinger.com/about-us" />
      </Helmet>
      <div
        className="fluid-container flex flex-col  items-center"
        style={{ background: "white", paddingTop: "100px" }}
        itemScope
        itemType="http://schema.org/Organization"
      >
   
        <header className=" text-center">
        <h1 className="text-center big-bold pb-10 about-us-title" itemProp="name">
          Uniklinger
        </h1>
          <div className="big-regular about-us-description">
            We are UKL, a Neterwala Group joint venture{" "}
            {screenWidth > 820 ? <br /> : ""} with Klinger AG in India.
          </div>
          <div className="small-regular line-height about-us-mini-description">
            We deliver customer-centric solutions worldwide, combining
            innovation with world-class manufacturing in India.
          </div>
        </header>
        {isMobile ? (
          <section class="flex flex-col items-center w-[100%]">
            <div className="gap-5 mt-30 flex items-center w-full z-10	 ">
              <div className="clients-team">
                <div className="text-[30px] team-count" itemProp="foundingDate">
                  40+
                </div>
                <div className="font-16-text text-[12px] color-primary">
                  Years Of Experience
                </div>
              </div>
              <div className="clients-team">
                <div className="text-[30px] team-count">1600+</div>

                <div className="font-16-text text-[12px] color-primary">
                  Industry Customers
                </div>
              </div>
            </div>

            <div className="gap-5 mt-30 flex items-center w-full z-10	 ">
              <div className="clients-team">
                <div className="team-count text-[30px]">4000+</div>
                <div className="font-16-text color-primary text-[12px]">
                  Plants Served
                </div>
              </div>
              <div className="clients-team">
                <div
                  className="team-count text-[30px]"
                  itemProp="numberOfEmployees"
                >
                  3
                </div>
                <div className="font-16-text color-primary text-[12px]">
                  Manufacturing Units
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="gap-5 mt-30 flex items-center w-full z-10	 ">
            <div className="clients-team">
              <div className="team-count" itemProp="foundingDate">
                40+
              </div>
              <div className="font-16-text color-primary">
                Years Of Experience
              </div>
            </div>
            <div className="clients-team">
              <div className="font-16-text color-primary">
                <div className="team-count">1600+</div>
                <div className="font-16-text color-primary">
                  Industry Customers
                </div>
              </div>
            </div>
            <div className="clients-team">
              <div className="team-count">4000+</div>
              <div className="font-16-text color-primary">Plants Served</div>
            </div>
            <div className="clients-team">
              <div className="team-count" itemProp="numberOfEmployees">
                3
              </div>
              <div className="font-16-text color-primary">
                Manufacturing Units
              </div>
            </div>
          </section>
        )}

        <div style={{ height: 250 }} className="relative w-full">
          <img
            style={{
              margin: 0,
              height: isMobile ? 335 : 350,
              width: 323,
              position: "absolute",
              left: isMobile ? "6%" : "39%",
              top: isMobile ? -86 : -86,
            }}
            alt="arrow"
            src={DottoedArrow}
          />
                  <div className="brief-uni"><span>A Brief History of Uniklinger</span></div>

        </div>

        <div
          className="row ml-0 mr-0 md:mt-10 sx:mt-5 product-card-container"
          style={{
            backgroundImage: `url(${Logo})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "100%",
          }}
        >
          <div className="col-md-6 md:p-0 paddingNone" style={{
            padding:0
          }}>
            <img
            style={{width:'100%'}}
              src={AboutImg1}
              alt="A Brief History of Uniklinger"
              className="banner-image p-5"
              description="A Brief History of Uniklinger"
            />
          </div>
          <div className="relative">
            {/* <img style={{ float: "right" }} src={Logo} /> */}
            <div className="col-md-6 fluid-contaier  pt-[20px] md:p-20   product-card-subContainer">
            <h2 className="text-[26px] md:pt-[20px] md:text-[36px] color-primary poppins-semibold vision-title">
              A Vision Takes Root
            </h2>
              <div className="text-align-left small-regular md:pt-[20px] md:pr-[10%]  text-[14px] width-full vision-description">
                For over forty years, we have enabled process plants be safer,
                more efficient and sustainable.
              </div>
              <div className="text-align-left small-regular md:pt-[20px] md:pr-[10%] text-[14px] width-full vision-description">
                We are deeply committed in our efforts to combat climate change.
                All our products and solutions are targeted to save energy
                thereby reducing carbon footprints of our customers.
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-40"
          style={
            screenWidth <= 820 ? { marginTop: "30px", paddingLeft: "5%" } : {}
          }
        >
          <div className="row align-items-center">
            <div className="col-md-5 p-0 neterwala-group-container">
              <div
                className="extra-big md:px-8 px-3 pb-3"
                itemProp="parentOrganization"
                itemScope
                itemType="http://schema.org/Organization"
              >
                About The Neterwala Group
                <hr className="horizontal-floating-line" />
              </div>
            </div>
            <div className="col-md-7 px-3 md:px  py-3 text-[50px] md:pt-[5%] md:pl-[20px] small-regular neterwala-description">
              The Neterwala Group is a diversified business conglomerate with
              leadership in metallurgy, aerospace manufacturing, and data
              services for the oil, gas, and mineralogy sectors. <br />
              <br />
              With a robust presence in India and the United Kingdom, the group
              is dedicated to pioneering innovation and maintaining the highest
              standards of quality across its wide-ranging operations.
            </div>
          </div>

          <div className="w-full neterwala-group-subcontainer px-10 mt-4">
            <div className="width-200 box">
              <div className="text-center box-section-title">
                60+ <br /> Years Of Experience
              </div>
            </div>
            <div className="dottedLine-class my-10"></div>
            <div className="width-200 box">
              <div className="text-center box-section-title">
                3000+ <br /> Customers
              </div>
            </div>
            <div className="dottedLine-class my-10"></div>
            <div className="width-200 box">
              <div className="text-center  box-section-title">
                15 <br /> Factories
              </div>
            </div>
            <div className="dottedLine-class my-10"></div>
            <div className="width-200 box">
              <div className="text-center  box-section-title">
                2500+ <br /> Employees
              </div>
            </div>
          </div>
        </div>
        {isMobile ? (
          <section className="container">
            <img
              src={AboutImg2}
              alt="INNOVATING INDUSTRIES WORLDWIDE"
              description="INNOVATING INDUSTRIES WORLDWIDE"
              className="banner-image p-10"
            />
            <div className="industries-worldwide-container   col-md-6 gap-2  ">
              <div className="industries-text pl-[16px]">
                INNOVATING INDUSTRIES WORLDWIDE
              </div>
              <div  className="poppins-regular text-[14px]  color-white mt-0 md:mt-14 industries-description">
                UKL harnesses cutting-edge technology and world-class
                manufacturing capabilities to deliver innovative solutions that
                enhance efficiency and sustainability across various industries.
                Our commitment to continuous improvement and customer-focused
                innovation drives us to set new benchmarks in industrial
                excellence globally.
              </div>
              <div className="horizontal-line mt-0 md:mt-[20px]"></div>
            </div>
          </section>
        ) : (
          <section
            className="row ml-0 mr-0 mt-12 relative"
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
          >
            <div className="col-md-6 p-0">
              <img
                src={AboutImg2}
                alt="INNOVATING INDUSTRIES WORLDWIDE"
                description="INNOVATING INDUSTRIES WORLDWIDE"
                className="banner-image p-5"
              />
            </div>
            <div className="col-md-6 gap-8 industries-worldwide-container p-16">
              <div className="industries-text ">
                INNOVATING INDUSTRIES WORLDWIDE
              </div>
              <div className="text-[14px] color-white mt-[20px] poppins text-left">
                UKL harnesses cutting-edge technology and world-class
                manufacturing capabilities to deliver innovative solutions that
                enhance efficiency and sustainability across various industries.
                Our commitment to continuous improvement and customer-focused
                innovation drives us to set new benchmarks in industrial
                excellence globally.
              </div>
              <div className="horizontal-line mt-[20px]"></div>
            </div>
          </section>
        )}

        <section class="container">
          <div class="row pt-50">
            <div class="col-md-12">
              {/* <!-- Nav tabs --> */}
              <div class="card flex-align-center flex-col">
                <ul
                  class="nav nav-tabs w-full flex-align-center space-around"
                  role="tablist"
                >
                  <li
                    role="presentation"
                    class="active w-33"
                    onClick={() => handlePartner("company")}
                  >
                    <a
                      href="#home"
                      aria-controls="home"
                      role="tab"
                      data-toggle="tab"
                      className="poppins-regular text-[14px]"
                      title="Our Company"
                    >
                      Our Company
                    </a>
                  </li>
                  {/* <li
                    role="presentation"
                    className="w-33"
                    onClick={() => handlePartner("csr")}
                  >
                    <a
                      href="#profile"
                      aria-controls="profile"
                      role="tab"
                      data-toggle="tab"
                      className="poppins-regular text-[14px]"
                    >
                      CSR
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className="w-33"
                    onClick={() => handlePartner("services")}
                  >
                    <a
                      href="#messages"
                      aria-controls="messages"
                      role="tab"
                      data-toggle="tab"
                      className="poppins-regular text-[14px]"
                    >
                      Our Manufacturing Services
                    </a>
                  </li>
                  <li
                    role="presentation"
                    className="w-33"
                    onClick={() => handlePartner("rnd")}
                  >
                    <a
                      href="#settings"
                      aria-controls="settings"
                      role="tab"
                      data-toggle="tab"
                      className="poppins-regular text-[14px] "
                    >
                      Research,Development & Innovation
                    </a>
                  </li> */}
                </ul>

                {/* <!-- Tab panes --> */}
                <div class="tab-content poppins-regular ml-10">
                  <div
                    role="tabpanel"
                    class="tab-pane active"
                    id="home"
                    style={
                      partnerTab === "company"
                        ? {
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                          }
                        : { display: "none" }
                    }
                  >
                    <img
                      src={AboutImg3}
                      alt="Our Company"
                      description="Engineer.Innovate.Deliver. Secure. Repeat"
                      style={{ height: 600, width: 360 }}
                      className="banner-image p-1 h-full engineer-image"
                    />
                    <div
                      className="col-md-8 p-30 mt-10 engineer-container"
                      style={{}}
                    >
                      <div className="font-46 ">
                        <div className="color-blue">Engineer. Innovate.</div>
                        <div
                          className="color-red"
                          style={{ position: "relative", top: "-10px" }}
                        >
                          Deliver. Secure. Repeat
                        </div>
                      </div>
                      <div className="font-16-regular leading-[28px] ">
                        UKL serves industries worldwide with a robust network of
                        distributors and dealers, ensuring our engineered
                        solutions reach every corner of the globe.
                        <br />
                        <br />
                        <br />
                        Our three manufacturing plants in western India produce
                        high-quality products that meet international standards,
                        enabling us to support greenfield and brownfield
                        projects across diverse sectors globally.
                      </div>
                    </div>
                  </div>
                  <div
                    role="tabpanel"
                    class="tab-pane"
                    id="profile"
                    style={
                      partnerTab === "csr"
                        ? { display: "flex", flexDirection: "row" }
                        : { display: "none" }
                    }
                  >
                    {" "}
                    <img
                      src={AboutImg3}
                      alt="CSR"
                      description="Engineer.Innovate.Deliver. Secure. Repeat"
                      style={{ height: 600, width: 360 }}
                      className="banner-image p-1 h-full"
                    />
                    <div className="col-md-8 p-30 mt-10" style={{}}>
                      <div className="font-46">
                        <span className="color-blue">Engineer. Innovate.</span>
                        <br />
                        <span className="color-red">
                          Deliver. Secure. Repeat
                        </span>
                      </div>
                      <div className="font-16-regular ">
                        Uniklinger embodies a philosophy of constant progress
                        and innovation encapsulated in the phrase "Engineer.
                        Innovate. Deliver. Secure." This commitment starts with
                        their team of skilled engineers who design and develop
                        top-notch fluid control and sealing solutions.
                        <br />
                        <br />
                        Uniklinger doesn't just stop there, though. They
                        prioritize continuous improvement and stay at the
                        forefront of the industry by actively seeking new and
                        advanced solutions. Delivering on their promises is
                        equally important.
                      </div>
                    </div>
                  </div>
                  <div
                    role="tabpanel"
                    class="tab-pane"
                    id="messages"
                    style={
                      partnerTab === "services"
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }
                        : { display: "none" }
                    }
                  >
                    <div className="big-heading">
                      Piston Valves & Bellow Sealed Valves
                    </div>
                    <div className="w-full flex flex-row justify-between">
                      <div
                        className="row ml-0 mr-0 mt-10 resources-card"
                        style={{
                          height: 300,
                          width: "32%",
                          backgroundColor: "#F4D7DF",
                        }}
                      >
                        <div className="relative ">
                          <img
                            style={{
                              height: "30%",
                              width: "30%",
                              float: "right",
                              marginBottom: 10,
                            }}
                            src={Piston}
                            alt="Piston Valves"
                            description="Piston Valves & Bellow Sealed Valves"
                          />
                          <div className="p-10 pt-[100px]">
                            <div className="heading ">Piston Valves</div>
                            <div className=" small-regular w-full font-14 pt-5">
                              Explore the engineering marvels behind Uni
                              Klinger's Piston Valves, designed for precise
                              fluid regulation in various industrial
                              applications. Learn about the innovative features
                              that ensure reliability, durability, and optimal
                              performance even in challenging environments{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row ml-0 mr-0 mt-10 resources-card"
                        style={{
                          height: 300,
                          width: "32%",
                          backgroundColor: "#E8D9E5",
                        }}
                      >
                        <div className="relative ">
                          <img
                            style={{
                              height: "30%",
                              width: "30%",
                              float: "right",
                              marginBottom: 10,
                            }}
                            src={Sealed}
                            alt="Sealed Valves"
                            description="Below Sealed Valves"
                          />
                          <div className="p-10 pt-[100px]">
                            <div className="heading ">Below Sealed Valves</div>
                            <div className=" small-regular w-full font-14 pt-5">
                              Explore the engineering marvels behind Uni
                              Klinger's Piston Valves, designed for precise
                              fluid regulation in various industrial
                              applications. Learn about the innovative features
                              that ensure reliability, durability, and optimal
                              performance even in challenging environments{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row ml-0 mr-0 mt-10 resources-card"
                        style={{
                          height: 300,
                          width: "32%",
                          backgroundColor: "#D5DDE4",
                        }}
                      >
                        <div className="relative ">
                          <img
                            style={{
                              height: "40%",
                              width: "40%",
                              float: "right",
                              marginBottom: 10,
                            }}
                            src={Steam}
                            alt="Piston Valves"
                            description="Piston Valves & Bellow Sealed Valves"
                          />
                          <div className="p-10 pt-[100px]">
                            <div className="heading ">Piston Valves</div>
                            <div className=" small-regular w-full font-14 pt-5">
                              Explore the engineering marvels behind Uni
                              Klinger's Piston Valves, designed for precise
                              fluid regulation in various industrial
                              applications. Learn about the innovative features
                              that ensure reliability, durability, and optimal
                              performance even in challenging environments{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <img src={AboutImg3} alt="card-image" style={{ height: 600, width: 360 }} className="banner-image p-1 h-full" />
                  <div className="col-md-8 p-30 mt-10" style={{}}>
                    <div className="font-46" >
                      <span className="color-blue">Engineer. Innovate.</span><br />
                      <span className="color-red">Deliver. Secure. Repeat</span>
                    </div>
                    <div className="font-16-regular ">
                      Uniklinger embodies a philosophy of constant progress and innovation encapsulated in the phrase "Engineer. Innovate. Deliver. Secure." This commitment starts with their team of skilled engineers who design and develop top-notch fluid control and sealing solutions.
                      <br /><br />
                      Uniklinger doesn't just stop there, though. They prioritize continuous improvement and stay at the forefront of the industry by actively seeking new and advanced solutions. Delivering on their promises is equally important.
                    </div>

                  </div> */}
                  </div>
                  <div
                    role="tabpanel"
                    class="tab-pane"
                    id="settings"
                    style={
                      partnerTab === "rnd"
                        ? { display: "flex", flexDirection: "row" }
                        : { display: "none" }
                    }
                  >
                    <img
                      src={AboutImg3}
                      alt="Our Company"
                      description="Engineer.Innovate.Deliver. Secure. Repeat"
                      style={{ height: 600, width: 360 }}
                      className="banner-image p-1 h-full"
                    />
                    <div className="col-md-8 p-30 mt-10" style={{}}>
                      <div className="font-46">
                        <span className="color-blue">Engineer. Innovate.</span>
                        <br />
                        <span className="color-red">
                          Deliver. Secure. Repeat
                        </span>
                      </div>
                      <div className="font-16-regular ">
                        Uniklinger embodies a philosophy of constant progress
                        and innovation encapsulated in the phrase "Engineer.
                        Innovate. Deliver. Secure." This commitment starts with
                        their team of skilled engineers who design and develop
                        top-notch fluid control and sealing solutions.
                        <br />
                        <br />
                        Uniklinger doesn't just stop there, though. They
                        prioritize continuous improvement and stay at the
                        forefront of the industry by actively seeking new and
                        advanced solutions. Delivering on their promises is
                        equally important.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="mt-40 w-[300px] md:w-[1000px]">
          <div className="big-bold text-center font-300">Certifications</div>
          <div className="wiper">
            <button class="wiper-button-left wiper-button__right  ">
              <img
                src={backLeft}
                alt="left"
                style={{
                  width: 50,
                  height: 50,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              />
              {/* <img src="https://www.iconpacks.net/icons/2/free-arrow-left-icon-3099-thumb.png" alt="left" />  */}
            </button>
            <div class="wiper-wrapper">
              <ul class="wiper-track">
                <li class="wiper-item"> 
                <div className="wiper__image" onClick={() => handlePDFClick(certificate1)}>
                <PDFThumbnail pdfUrl={certificate1} />
              </div>
                </li>
                <li class="wiper-item active-swipe">
                <div className="wiper__image" onClick={() => handlePDFClick(certificate2)}>
                <PDFThumbnail pdfUrl={certificate2} />
              </div>
                </li>
                <li class="wiper-item">
                <div className="wiper__image" onClick={() => handlePDFClick(certificate3)}>
                <PDFThumbnail pdfUrl={certificate3} />
              </div>
                </li>
                <li class="wiper-item">
                <div className="wiper__image" onClick={() => handlePDFClick(certificate3)}>
                <PDFThumbnail pdfUrl={certificate3} />
              </div>
                </li>
              </ul>
            </div>
            <button class="wiper-button-right wiper-button__left">
              <img
                src={backRight}
                alt="left"
                style={{
                  width: 50,
                  height: 50,
                  paddingTop: 10,
                  paddingBottom: 10,
                  transform: "rotate(180deg)",
                }}
              />
              {/* <img src="https://www.iconpacks.net/icons/2/free-arrow-left-icon-3099-thumb.png" alt="right" /> */}
            </button>

         
          </div>
        
        </div> 


        <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={selectedPDF}
        onClick={handleClose}
      >

        {
          downloading ?
          <CircularProgress color="#2B9CFF" />:
          <div className="pdf-popup">
          <div className="pdf-popup-content">
            <p>Would you like to download the PDF?</p>

            <div className="flex flex-row justify-between  items-center">
            <button className="enquire" style={{fontSize:16,textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={handleDownload}>Download</button>
            <button className="view-more flex items-center justify-center" style={{color:'black',fontSize:16,border:'1px solid black',}} onClick={() => setSelectedPDF(null)}>Cancel</button>
       
            </div>
              </div>
        </div>
        }
       
     
      </Backdrop>
  
{/*         
         {isMobile ? (
          <div>
            <button class="wiper-button-left wiper-button__right ">
              <img
                src={backLeft}
                alt="left"
                style={{
                  width: 50,
                  height: 50,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              />
             <img src="https://www.iconpacks.net/icons/2/free-arrow-left-icon-3099-thumb.png" alt="left" />
            </button>

            <button class="wiper-button-right wiper-button__left">
              <img
                src={backRight}
                alt="left"
                style={{
                  width: 50,
                  height: 50,
                  paddingTop: 10,
                  paddingBottom: 10,
                  transform: "rotate(180deg)",
                }}
              />
              <img src="https://www.iconpacks.net/icons/2/free-arrow-left-icon-3099-thumb.png" alt="right" />
            </button>
          </div>
         ) : null}   */}
        <div
          className={
            screenWidth >= 820
              ? "row mt-20 d-flex items-center "
              : "global-footprint-container mt-[100px]"
          }
        >
          <div className="col-md-5 pl-36 global-footprint-content">
            <div className="big-regular global-footprint-title">
              <span className="font-300">Our Global</span> Footprint
            </div>
            <div className="font-16-regular leading-[32px]">
              UKL proudly serves industries worldwide with a robust network of
              distributors and dealers, ensuring our engineered solutions reach
              every corner of the globe. Our three manufacturing plants in
              western India produce high-quality products that meet
              international standards, enabling us to support greenfield and
              brownfield projects across diverse sectors globally.
            </div>
          </div>
          <div className="col-md-7 global-footprint-image">
            <img style={{width:'100%'}} src={AboutImg4} alt="About-Img" className="" />
          </div>
        </div>

        {/* <div class="mt-[50px] scroller " data-speed="fast">
          <div className="big-bold text-center">
            <span className="font-300">Our</span> Clients
          </div>
          <div className="box-wrapper pt-12 gap-5">
            <div className="scroller-box"></div>
            <div className="scroller-box"></div>
            <div className="scroller-box"></div>
            <div className="scroller-box"></div>
            <div className="scroller-box"></div>
            <div className="scroller-box"></div>
          </div>
        </div> */}

        <div className="mt-24" style={{width:'100%'}}>
          <a href="https://uniklinger.com/careers" title="careeers">
            <img
              src={isMobile ? AboutBannerMobile : AboutBanner}
              alt="Careers"
              style={{width:'100%'}}
              description="Careers"
              className="about-banner-image"
            />
          </a>
        </div>
      </div>

      
    </>
  );
};

export default AboutUs;
