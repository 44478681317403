import React, { useEffect, useState } from "react";
import FancyCarousel from "react-fancy-circular-carousel";
import IndustrySec from "./IndustrySec";
import IndustryExample from "../assets/images/industry-example.svg";
import OilRefineries from "../assets/images/oil-refineries.svg";
import ChemicalPetrochemical from "../assets/images/chemical-petrochemical.svg";
import Pharmaceutical from "../assets/images/pharmaceuticals.svg";
import FoodBaverage from "../assets/images/food-beverage.svg";
import Paper from "../assets/images/paper.svg";
import Textiles from "../assets/images/textiles.svg";
import TyreManufacturing from "../assets/images/tyre-manufacturing.svg";

const SolutionsCarousel = () => {
  const images = [
    OilRefineries,
    ChemicalPetrochemical,
    Pharmaceutical,
    FoodBaverage,
    Paper,
    Textiles,
    TyreManufacturing,
  ];
  const info = [
    <IndustrySec industry="oil-refinery" />,
    <IndustrySec industry="chemical-petrochemical" />,
    <IndustrySec industry="pharmaceutical" />,
    <IndustrySec industry="food-beverages" />,
    <IndustrySec industry="paper" />,
    <IndustrySec industry="textiles" />,
    <IndustrySec industry="tyre-manufacturing" />,
  ];
  const [focusElement, setFocusElement] = useState(0);

  useEffect(() => {
    console.log(focusElement);
    let buttons = document.querySelectorAll(
      ".fancy-carousel-navigation-button"
    );
    buttons.forEach((button, index) => {
      if (index === 1) {
        console.log(button);
        button.innerHTML = `<i class="fas fa-chevron-down"></i>`;
      }
      if (index === 0) {
        button.innerHTML = `<i class="fas fa-chevron-down"></i>`;
      }
    });
    let carouselElements = document.querySelectorAll(".fancy-carousel-element");
    carouselElements.forEach((element, index) => {
      console.log(element.children[0].style);
      if (index === focusElement) {
        element.children[0].style.filter = "none";
      } else {
        element.children[0].style.filter = "grayscale(100%)";
      }
      //   if(index === focusElement){
      //     element.style.width='120px';
      //     element.style.height='120px';
      //   }
      //    if(index === focusElement -1){
      //     element.style.width='80px';
      //     element.style.height='80px';
      //   }
      //    if(index === focusElement + 1){
      //     element.style.width='80px';
      //     element.style.height='80px';
      //   }
      //   if(index === focusElement -2){
      //     element.style.width='40px';
      //     element.style.height='40px';
      //   }
      //    if(index === focusElement + 1){
      //     element.style.width='40px';
      //     element.style.height='40px';
      //   }
    });
  }, [focusElement]);
  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setScreenWidth(entry.contentRect.width);
      }
    });

    // Start observing the selected element
    resizeObserver.observe(document.body);
  }, [screenWidth]);
  return (
    <div
      style={screenWidth < 820 ? { display: "none" } : { position: "relative",marginBottom: screenWidth >= 2560 ? '100px':'0px'}}
      className="md:mt-[50px] md:mb-[50px] lg:mb-[50px]  col-md-12 fluid-container"
    >
      {/* <div
        className="carousel custom-rotating-carousel"
        style={{ transform: "rotate(-90deg)" }}
      >
        <FancyCarousel
          images={images}
          carouselRadius={200}
          peripheralImageRadius={60}
          centralImageRadius={0}
          setFocusElement={setFocusElement}
          focusElementStyling={{}}
          borderWidth={0}
          borderHexColor={"808080"}
        />
        <div className="info-box-wrapper">
          <div class="info-container">
            <p> {info[focusElement]} </p>
          </div>
        </div>
      </div> */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20rem",
          margin: "5rem 0",
      

        }}
        className="carousel-section  col-md-12 "
      >
        <div  style={{ order: 2,display:'flex',justifyContent:'center' }} className="col-md-6">
        <FancyCarousel
          images={images}
          carouselRadius={200}
          peripheralImageRadius={60}
          centralImageRadius={0}
          setFocusElement={setFocusElement}
          focusElementStyling={{}}
          borderWidth={0}
          borderHexColor={"808080"}
        />
        </div>
        
        <div style={{ order: 1 }} className="info-box-wrapper col-md-6">
        <div >
            <p> {info[focusElement]} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsCarousel;
