import React, { useState, useEffect } from "react";
import Pharmaceuticals from "../assets/images/pharmaceuticals.jpg";
import Tyre from "../assets/images/tyre.jpg";
import Paper from "../assets/images/paper.jpg";
import Textile from "../assets/images/textile.jpg";
import FoodBeverages from "../assets/images/food-beverages.jpg";
import ChemicalPetrochemical from "../assets/images/chemical-petrochemical.jpg";
import OilRefinery from "../assets/images/oil-refinery.jpg";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const MobileIndustrySolution = () => {
  const [industry, setIndustry] = useState("food-beverages");
  const [data, setData] = useState({});

  useEffect(() => {
    const industryData = {
      "food-beverages": {
        image: FoodBeverages,
        description: "Maintain hygiene and efficiency in food and beverage production with our reliable sealing and steam management solutions, ensuring safe and leak-free operations",
        title: "Food & Beverage",
      },
      "paper": {
        image: Paper,
        description: "Improve paper production processes with our durable valves and steam traps, designed to handle high temperatures and pressures efficiently",
        title: "Paper",
      },
      "textiles": {
        image: Textile,
        description: "Optimize textile manufacturing with our efficient steam and condensate management solutions, ensuring smooth and energy-efficient operations",
        title: "Textile",
      },
      "tyre-manufacturing": {
        image: Tyre,
        description: "Enhance tyre production with our leak-proof valves and effective steam traps, designed to withstand the high temperatures and pressures of the manufacturing process",
        title: "Tyre Manufacturing",
      },
      "oil-refinery": {
        image: OilRefinery,
        description: "Enhance efficiency and safety with our leak-proof valves, gaskets, and steam traps, designed to handle the demanding conditions of oil refining processes",
        title: "Oil Refineries",
      },
      "chemical-petrochemical": {
        image: ChemicalPetrochemical,
        description: "Ensure zero leakage and optimal process control with our robust valves, gaskets, and condensate management solutions, tailored for chemical and petrochemical applications",
        title: "Chemical & Petrochemical Plants",
      },
      "pharmaceutical": {
        image: Pharmaceuticals,
        description: "Meet stringent pharmaceutical standards with our high-performance valves, gaskets, and steam traps, ideal for applications involving pure steam and critical media",
        title: "Pharmaceuticals",
      },
    };

    setData(industryData[industry]);
  }, [industry]);

  function handleChange(event) {
    setIndustry(event.target.value);
  }

  return (
    <div itemScope itemType="https://schema.org/WebPage" className="fluid-container mt-[100px]">
      <div className="industry-select-container" itemScope itemType="https://schema.org/Thing">
        <div itemProp className="poppins" style={{fontSize:18}} > Solution Industry</div>
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label" itemProp="name">Solution Industry</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={industry}
           
            onChange={handleChange}
          >
            <MenuItem sx={{fontSize:16}} value="oil-refinery" itemProp="additionalType">Oil Refineries</MenuItem>
            <MenuItem sx={{fontSize:16}}  value="chemical-petrochemical" itemProp="additionalType">Chemical & Petrochemical Plants</MenuItem>
            <MenuItem sx={{fontSize:16}}  value="pharmaceutical" itemProp="additionalType">Pharmaceuticals</MenuItem>
            <MenuItem sx={{fontSize:16}}  value="food-beverages" itemProp="additionalType">Food & Beverage</MenuItem>
            <MenuItem sx={{fontSize:16}}  value="paper" itemProp="additionalType">Paper</MenuItem>
            <MenuItem sx={{fontSize:16}}  value="textiles" itemProp="additionalType">Textiles</MenuItem>
            <MenuItem sx={{fontSize:16}}  value="tyre-manufacturing" itemProp="additionalType">Tyres Manufacturing</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="solution-title" itemProp="headline">
        <b>Solutions</b> Industry wise
      </div>
      <div className="solution-image-container" itemScope itemType="https://schema.org/ImageObject">
        <img
          src={data.image}
          alt={data.title}
          description={data.description}
          className="solution-image"
          itemProp="contentUrl"
        />
        <div className="solution-relative-text">
          <div className="solution-image-subtitle" itemProp="caption">Most Reliable</div>
          <div className="solution-main-image-title" itemProp="name">
            {data.title}
          </div>
        </div>
      </div>
      <div className="solution-description" itemProp="description">
        {data.description}
      </div>
    </div>
  );
  
};

export default MobileIndustrySolution;
