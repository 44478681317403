import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Popover from '@mui/material/Popover';
import { Search as SearchIcon } from "@mui/icons-material";
import  Logo from "../../assets/images/updatedLogo.png";
import { ReactComponent as SmallLogo } from "../../assets/images/updateSmallLogo.svg";
import { ReactComponent as SearchIcon1 } from "../../assets/images/SearchIcon1.svg";
import { ReactComponent as DropdownArrow } from "../../assets/images/dropdownArrrow.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/Icon.svg";
import Arrow1 from "../../assets/images/Vector.svg";
import SearchBar from "material-ui-search-bar";
import "./header-desktop.css";
import { useLocation, useNavigate } from "react-router-dom";
const drawerWidth = 300;
const navItems = [
  { title: "ABOUT US", url: "/about-us" },
  { title: "PRODUCTS", url: "/products" },
  { title: "SOLUTIONS", url: "/industry-solutions" },
  { title: "INSIGHTS", url: "/insights" },
  { title: "CAREERS", url: "/careers" },

  // { title: "PARTNER WITH US", url: "/" },
];
function DrawerAppBar(props) {
  const navigate = useNavigate();
  const routes = [
    '/',
    '/about-us',
    '/contact-us',
    '/careers',
    '/products',
    '/industry-solutions',
    '/blog',
    '/about-us',
    '/product-detail',
    '/products-listing',
    '/privacy-policy',
    '/industry-solution-details',
    '/insights',
    '/insights-details',
   '/about-us/company-updates'

    // Add more routes as needed
  ];
  const location = useLocation();
  
  // Check if the current path matches any of the routes
  const currentPath = location.pathname;
  const isKnownRoute = routes.includes(currentPath);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const theme = useTheme();
  const [selectedSubroute, setSelectedSubroute] = useState('');
console.log(location.pathname,"ii")
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverItemClick = (subroute) => {
    setSelectedSubroute(subroute);
    handleClose(); // Close the popover
    navigate(subroute); // Navigate to the selected subroute
  };
  const handleClick = (event) => {
    console.log(event,"event")
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;



  
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center"}}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MENU
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
            
            onClick={() => {
             
                navigate(item.url);
              
            }}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
{
          isSmallScreen ? (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate('/contact-us');
                }}
                sx={{ textAlign: "center" }}
              >
                <ListItemText primary={"PARTNER WITH US"} />
              </ListItemButton>
            </ListItem>
          ) : ''
        }


      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    isKnownRoute ?
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar
          sx={{
            background: "#FFF",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: { xs: "none", sm: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <List sx={{ display: { xs: "none", sm: "flex" }, ml: 2 }}>
              {navItems.map((item) => (
                <ListItem key={item} disablePadding>
                  <ListItemButton  aria-describedby={id}
                       onClick={(event) => {
                        console.log(item.title === 'ABOUT US', "test");
                        if (item.title === 'ABOUT US') {
                          handleClick(event);
                        } else {
                          navigate(item.url);
                        }
                      }}
                    sx={{ textAlign: "center" }}
                  >
                    <ListItemText
                      primary={item.title}
                      sx={{ fontWeight: 500, fontSize: 13 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <IconButton
              color="inherit"
              edge="center"
              onClick={() => {
                navigate("/");
              }}
              sx={{ width: 100, height: 39,    
              }}
            >
               <img
                src={Logo}
                alt="image160I2026"
                style={{height:43,width:'100%'}}

              />
            </IconButton>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <SearchBar
                searchIcon={<SearchIcon1 />}
                style={{ width: 223, height: 40, fontSize: 12, marginRight: '46px', }}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between",position: "relative" }}>
                {/* <Select
                labelId="language-select-label"
                id="language-select"
                value={10}
                disableUnderline
                IconComponent={DropdownArrow}
                variant="standard"
                sx={{
                  fontSize: 13,
                  textTransform: "capitalize",
                  borderRadius: 0,
                  fontWeight: 500,
                  ml: 2,
                }}
              >
                <MenuItem value="">NONE</MenuItem>
                <MenuItem value={10}>ENG</MenuItem>
              </Select> */}
              <div className="d-flex items-center">
<div>
                <span className="contact-us-text  d-flex">
                  <div className="d-flex">
                    <a className=" header-menu mr-3" href="/contact-us">
                      PARTNER WITH US{" "}
                    </a>
                    <img className="hover:rotate-[-410deg]" src={Arrow1} />
                  </div>
                 
                </span>
                <div className="contact-us"></div>
              </div>
</div>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
             edge="start"
              onClick={() => {
                navigate("/");
              }}
              sx={{ width: 100, height: 50 }}
            >
       
               <img
                src={Logo}
                alt="Uniklinger"
                style={{height:35,width:'65%'}}
              />
         
            </IconButton>
            <Box sx={{ display: "flex", justifyContent:searchOpen ? "flex-end": "space-between",width:searchOpen?'80%':'20%' }}>
              {/* <Select
                labelId="language-select-label"
                id="language-select"
                value={10}
                disableUnderline
                IconComponent={DropdownArrow}
                variant="standard"
                sx={{
                  fontSize: 13,
                  textTransform: "capitalize",
                  borderRadius: 0,
                  fontWeight: 500,
                  ml: 2,
                }}
              >
                                <MenuItem value={10}>ENG</MenuItem>
              </Select> */}
              {searchOpen ? (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search..."
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    width:searchOpen ? 150: 86,
                    height: 27,
                    marginRight:searchOpen ? '10px' :0
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleSearchClick}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoFocus
                />
              ) : (
                <IconButton
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    width: 50,
                    height: 27,
                    marginLeft:searchOpen ?0:0
                  }}
                  onClick={handleSearchClick}
                >
                  <SearchIcon />
                </IconButton>
              )}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { xs: "flex", sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
     
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'white !important',     // Background color
            boxShadow: 3,         // Add shadow for depth
            // borderRadius: 2,  
            width:'25%',    // Rounded corners
            p: 2,                 // Padding
          },
        }}
      >
        <Typography sx={{ p: 1,fontSize:14,color:location.pathname=='/about-us/company' ?'#DB1F51':'#000'? '&:hover': {
      color: '#DB1F51', // Change text color on hover
    } }}
            onClick={() => handlePopoverItemClick('/about-us/company')}

        >Our Company</Typography>
        <Typography sx={{ p: 1,fontSize:14,color:location.pathname=='/about-us/csr' ?'#DB1F51':'#000'? '&:hover': {
      color: '#DB1F51', // Change text color on hover
    } }}
            onClick={() => handlePopoverItemClick('/about-us/csr')}
            >CSR</Typography>

<Typography sx={{ p: 1,fontSize:14,color:location.pathname=='/about-us/manufacturing-resources' ?'#DB1F51':'#000'? '&:hover': {
      color: '#DB1F51', // Change text color on hover
    } }}
            onClick={() => handlePopoverItemClick('/about-us/manufacturing-resources')}

        >Our Manufacturing Resources</Typography>
       <Typography sx={{ p: 1,fontSize:14,color:location.pathname=='/about-us/rd-innovation' ?'#DB1F51':'#000'? '&:hover': {
      color: '#DB1F51', // Change text color on hover
    } }}
            onClick={() => handlePopoverItemClick('/about-us/rd-innovation')}

        >Research, Development & Innovation</Typography>
       <Typography sx={{ p: 1,fontSize:14,color:location.pathname=='/about-us/company-updates' ?'#DB1F51':'#000'? '&:hover': {
      color: '#DB1F51', // Change text color on hover
    } }}
            onClick={() => handlePopoverItemClick('/about-us/company-updates')}
            >Company Updates</Typography>

      </Popover>
    </Box>
    :
    null
  );
}
DrawerAppBar.propTypes = {
  window: PropTypes.func,
};
export default DrawerAppBar;
